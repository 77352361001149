/**
 * table row render functions.
 */

import { roundToDollars } from "./MoneyUtils";

export function renderProductTableRow(product, recipeMap, ingredientMap) {
    let image1 = 'https://drive.google.com/file/d/' + product.google_image_file_id + '/view?usp=sharing'
    let image2 = 'https://photos.google.com/share/' + product.google_image_file_id 
    /**
     * This following google link works as of April 5, 2024
     */
    let image3 = 'https://lh3.googleusercontent.com/d/' + product.google_image_file_id
    let image = 'https://drive.google.com/file/d/' + product.google_image_file_id + '/preview'
    let recipe = recipeMap.get(product.recipe); 
    const ingredientList = new Array();
    recipe.ingredients.forEach(currentIngredientRef => {
        let refParts = currentIngredientRef.split(':');
        let currentIngredient = ingredientMap.get(refParts[0]);
        let ingredientName = '';
        if (currentIngredient.description.indexOf('-') > 0) {
            let ingredientParts = currentIngredient.description.split('-');
            ingredientName = ingredientParts[0];
        } else {
            ingredientName = currentIngredient.description;
        }
        if(!ingredientList.includes(ingredientName.trim())) {
            ingredientList.push(ingredientName.trim());
        }
    });

    return (
        <>
            <tr className='productTableRow'>
                <td className='productTableCellLeft'>
                    <span className='productTitle'>{product.name}</span>
                    <p>
                        {product.description}
                    </p>
                    <p>
                        Ingredients: {ingredientList + ', Love'}
                    </p>
                    <p>
                        Price: {roundToDollars(product.price)} Per: {product.recipe_qty}
                    </p>
                </td>
                <td className='productTableCellRight'>
{/*                 <iframe frameborder="0" title="image" src={image} allow="autoplay"></iframe>
 */}                    <img className='productImage'
                        src={image3}
                        alt="product" />
                </td>
            </tr>
        </>
    );

}