import React from 'react';
import './../index.css';

export class Title extends React.Component {

  render() {

    return (
      <>
        {
          /*
          * Title section
          */
        }
        <div className='banner'>
          <div className='titleBackground'>
            <div className='titleImage'></div>
          </div>
        </div>

      </>
    );
  }


}
