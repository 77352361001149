
const PRODUCT_SEPARATOR = '|';
const COMMENT_LINE = '#';

export class ProductRecord {
  name = ""
  description = ""
  priceDescription = ""
  uiId = ""
  price = ""
  ingredients1 = ""
  ingredients2 = ""
  category=""
  constructor(uiId, name, description, priceDescription, price, ingredients1, ingredients2, category) {
    this.uiId = uiId;
    this.name = name;
    this.description = description;
    this.priceDescription = priceDescription;
    this.price = price;
    this.ingredients1 = ingredients1;
    this.ingredients2 = ingredients2;
    this.category = category;
  }
}

export class OrderRecord {
  product = ""
  quantity = ""
  isSelected = ""
  constructor(product, quantity, isSelected) {
    this.product = product;
    this.quantity = quantity;
    this.isSelected = isSelected;
  }
}

export function getMap(productFile) {
  const productMap = new Map();
  const eol = require("eol");
  /** 
   * Split text into lines and iterate over each line.
   */
  let lines = eol.split(productFile);
  //lines.forEach(function (line) {
  for (const line of lines) {
    if ('' === line) {
      return;
    }
    if (line.startsWith(COMMENT_LINE)) {
      continue;
    }

    let tokens = line.split(PRODUCT_SEPARATOR);
    let currentProduct = new ProductRecord(tokens[0],tokens[1],tokens[2],tokens[3],tokens[4],tokens[5],tokens[6],tokens[7]);
    productMap.set(tokens[0],currentProduct);
  }
 
return productMap;
}

