import React from 'react';
import '../index.css';

export class EBSite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      map: new Map(),
      currentLevelIndex: '1',
      searchValue: '',
      cookieSet: false,
    }
  }

  render() {

    return (
      <>
        <div className='bodyText'>
          Finally, you can get traditional homemade yeast bread in Johnstown, Colorado!  Artisan bread is fine once in a while,
          but what about good old-fashioned homemade bread?  Elevated Baking has what you want and need whether it is
          white bread, Italian bread, Sheepherder's bread, or delicious cinnamon rolls and other sweet treats.  We even
          offer gluten-free cookies!  Order your elevated baked goods today!
        </div>
        <div className='news'>
         We've moved!  Thank you to all our loyal customers in Pagosa Springs, CO!
         We've moved back to our roots in Northern Colorado to be near family.
        </div>
      </>
    );
  }
}
