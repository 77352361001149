import React from 'react';
import './../index.css';
import { Disclaimer } from './Disclaimer';
import { renderProductTableRow } from './../util/TableRows'


export class GlutenFree extends React.Component {

  render() {

    return (
      <>
        {
          /*
          * Products section
          */
        }
        <div className='topContainer'>
          <table className='productTable'>
            <tbody>
              {
                [...this.props.docMap].map(([key, value]) => {
                  if (value.category === 'Gluten Free' && value.on_website) {
                    return renderProductTableRow(value, this.props.recipeMap, this.props.ingredientMap);
                  } else {
                    return "";
                  }
                }
                )
              }
            </tbody>
          </table>
          <Disclaimer />
        </div>
      </>
    );
  }


}
