import React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { Disclaimer } from './Disclaimer';

export class About extends React.Component {

  render() {

    return (
      <>
        {
          /*
          * About section
          */
        }
        <div className='bodyText'>
          Life is busy! Not everyone has the time nor the energy to
          bake their own breads and sweet treats.
          I would love to be your baker on call. I have cherished recipes that
          I grew up with that I want to share with you. My baked goods will elevate
          your taste buds to a new level. No preservatives and only high quality
          ingredients. Home baked goodness infused with love!
          <p>
            - Lisa Botterill
          </p>
      </div>
        <Disclaimer />
      </>
    );
  }


}
