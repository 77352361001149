import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import {
  BrowserRouter
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import './fonts/Bakerie/BakerieSmooth-Black.otf';
import { Footer } from './ui/Footer';
import { NavBar } from './ui/NavBar';
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { database } from './firebase-config';



class ElevatedBakingApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allDocs: [],
      docRef: "",
      docMap: new Map(),
      recipeMap: new Map(),  
      ingredientMap: new Map(),  
    }
  }

  async readIngredients() {
    this.setState({
      ingredientMap: new Map(),
    });

    try {
      const docQuery = query(collection(database, "ingredients"), orderBy("description", "asc"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.state.ingredientMap.set(doc.id, doc.data());
      });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }

  async readRecipes() {
    let allDocs = new Array();
    this.setState({
      recipeMap: new Map(),
    });

    try {
      const docQuery = query(collection(database, "recipes"), orderBy("name", "asc"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        this.state.recipeMap.set(doc.id, doc.data());
      });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }


  async readProducts() {
    let allDocs = new Array();
    this.setState({
      docMap: new Map(),
    });

    try {
      const docQuery = query(collection(database, "products"), orderBy("name", "asc"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        this.state.docMap.set(doc.id, doc.data());
        allDocs.push(doc);
      });
      this.setState({ allDocs: allDocs });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }

  componentDidMount() {
    this.readIngredients()
    .then(this.readRecipes())
    .then(this.readProducts());
  }

  render() {

    return (

      <div>
        {/*
            * Navbar section
            */}
        <BrowserRouter>
            <NavBar 
                docMap={this.state.docMap}
                recipeMap={this.state.recipeMap}
                ingredientMap={this.state.ingredientMap}
                />
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Helmet>
      <title>Elevated Baking</title>
      <link rel="icon" href="../src/images/rolling_pin.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto+Condensed&family=Unite&display=swap" rel="stylesheet" />
    </Helmet>
    <ElevatedBakingApp
    />
  </React.StrictMode >,
  document.getElementById('root')
);