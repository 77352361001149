// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


let firebaseConfig = {
  apiKey: "AIzaSyD6nwkAybjYj4QOnr92_6dk764Y_ImkmKk",
  authDomain: "elevated-baking-dashboard.firebaseapp.com",
  projectId: "elevated-baking-dashboard",
  storageBucket: "elevated-baking-dashboard.appspot.com",
  messagingSenderId: "443629498964",
  appId: "1:443629498964:web:5496d8af131398f921b3c1",
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getFirestore  (app);
export default app;
