import React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { Disclaimer } from './Disclaimer';

export class Locations extends React.Component {

  render() {

    return (
      <>
        {
          /*
          * Locations section
          */
        }
        <div className='bodyText'>
          We currently serve the Northern Colorado front range.  We deliver to Johnstown and can
          make arrangements to meet with a delivery near Highway 34 and I-25.
      {/*     <table>
            <tr>
              <td>
                <a href='http://www.thechokecherrytree.com/' rel='noreferrer' target='_blank'>The Choke Cherry Tree</a>, 
                56 Talisman Dr # 8C, Pagosa Springs, CO 81147
              </td>
            </tr>
          </table> */}
      </div>
        <Disclaimer />
      </>
    );
  }


}
