import React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';


export class Disclaimer extends React.Component {

  render() {

    return (
      <>
        {
          /*
          * Disclamer section
          */
        }
        <div className='disclaimerContainer'>
          <div className='disclaimerText'>
            This product was produced in a home kitchen
            that is not subject to state licensure or
            inspection and that may also process common
            food allergens such as tree nuts, peanuts,
            eggs, soy, wheat, milk, fish and crustacean
            shellfish. This product is not intended for
            resale.  For more information, see the <a href='https://cdphe.colorado.gov/cottage-foods-act' target='_blank' rel='noreferrer'>Colorado Cottage Food Act</a>
          </div>
        </div>
      </>
    );
  }


}
