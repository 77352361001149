import React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';


export class Footer extends React.Component {

  render() {

    return (
      <>
        {
          /*
          * Footer section
          */
        }
        <div className='footer'>
        <table className='footerTable'>
          <tbody>
          <tr className='footerTableRow'>
            <td>
              <span className='footerText'>
                Email: lisa@elevatedbaking.biz
              </span>
            </td>
            <td>
              <span className='footerText'>
                Phone: (970) 673-9113
              </span>

            </td>
          </tr>
          </tbody>
        </table>
        </div>
      </>
    );
  }


}
