import React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import facebook_icon from './../images/facebook_icon.png';
import instagram_icon from './../images/instagram_icon.png';


export class Contact extends React.Component {

  render() {

    return (
      <>
        {
          /*
          * About section
          */
        }
        <div className='contactText'>
          <p>
            lisa@elevatedbaking.biz
          </p>
          <p>
            (970) 673-9113
          </p>
          <p>
            <a href='https://www.facebook.com/100086442454609/' rel='noreferrer' target='_blank'>
              <img className='socialMediaImage' src={facebook_icon} alt="facebook" />
              Elevated Baking    
            </a>
          </p>
          <a href='https://www.instagram.com/elevatedbakingpagosa/' rel='noreferrer' target='_blank'>
            <img className='socialMediaImage' src={instagram_icon} alt="instagram" />
            #elevatedbakingpagosa
          </a>
             
        </div>
      </>
    );
  }


}
